<template>
	<div v-if="hasPermission">
		<listAccountEntry />
	</div>
</template>

<script>
// @ is an alias to /src

// import Loans from '@/components/content/content-loans/ContentLoans.vue'
import listAccountEntry from '@/components/content/content-acc/ContentlistAccountEntry.vue'
import { getPermissions } from '../components/util/liblist'
export default {
	name: 'AccountTransactions',
	components: {
		listAccountEntry,
	},
	data() {
		return {
			currentComponent: 'AccTransactions',
			'hasPermission': false,
		}
	},
	mounted() {
		if (getPermissions('accounting.report.journal')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>

<template>
  <div class="container px-2 mx-auto dark:bg-gray-900">
    <loading :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage">
    </loading>
    <div class="flex flex-row space-x-6 rounded-t-xl">
      <div class="flex items-center justify-start">
        <h2
          class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
        >
          Libro Diario
        </h2>
      </div>
    </div>

    <nav class="sticky container mx-auto w-full top-0">
      <div
        class="flex flex-row flex-shrink-0 items-center justify-start shrink-0 text-sm font-bold uppercase rounded-t-lg bg-gradient-to-t from-blue-600 to-blue-500 text-white"
      >
        <div
          class="flex items-center justify-center w-14 h-10 px-1"
        >
              <span>N.</span>
            </div>
            <div
              class="flex items-center w-20 h-10 px-1"
            >
              <span>Asiento</span>
            </div>
            <div
              class="flex items-center w-24 h-10 px-1"
            >
              <span>Fecha</span>
            </div>
            <div
              class="flex items-center flex-1 w-32 h-10 px-1"
            >
              <span>Cuenta</span>
            </div>
            <div
              class="flex items-center flex-1 w-44 h-10 px-1"
            >
              <span>Descripción</span>
            </div>
            <div
              class="flex items-center w-40 h-10 px-1"
            >
              <span>Referencias</span>
            </div>
            <div
              class="flex items-center w-24 h-10 px-1"
            >
              <span>Debito</span>
            </div>
            <div
              class="flex items-center w-24 h-10 px-1"
            >
              <span>Crédito</span>
            </div>
          </div>
        </nav>

        <div class="overflow-hidden border-l-2 border-r-2">
          <div
            class="flex flex-shrink-0 justify-start text-sm hover:bg-blue-100 duration-150"
            v-for="(item, index) in objAccount"
            :key="index"
          >
            <div
              class="flex items-center pl-3 w-14 h-6 px-1 border-b border-gray-300"
            >
              <span>
                <router-link
                  :to="{
                    name: 'NumberAccTrans',
                    params: {
                      url: 'entrynumber',
                      number: item.accounting_entry_code,
                    },
                  }"
                  class="border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200"
                >
                  {{ index + 1 }}
                </router-link>
              </span>
            </div>
            <div
              class="flex items-center w-20 h-6 px-1 border-b border-gray-300"
            >
              <router-link
                :to="{
                  name: 'NumberAccTrans',
                  params: {
                    url: 'entrynumber',
                    number: item.accounting_entry_code,
                  },
                }"
                  class="border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200"
              >
                {{ item.accounting_entry_code }}
              </router-link>
            </div>
            <div
              class="flex items-center text-left w-24 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
            >
              <span>{{ item.transactionDate }}</span>
            </div>
            <div
              class="flex items-center text-left flex-1 w-32 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
            >
              <span>{{ item.accountCode }} - {{ item.accountName }}</span>
            </div>
            <div
              class="flex items-center text-left flex-1 w-40 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
            >
              <span>{{ item.description }}</span>
            </div>
            <div
              class="flex items-center text-left w-44 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"
            >
              <span
                >( {{ item.reference_type }}: {{ item.reference }}) P:{{
                  item.auxiliary
                }}</span
              >
            </div>
            <div
              class="flex items-center w-24 h-6 px-1 border-b border-gray-300"
            >
              <span>{{
                item.debit > 0 ? formatNumber(item.debit, ",", "$", true) : ""
              }}</span>
            </div>
            <div
              class="flex items-center w-24 h-6 px-1 border-b border-gray-300"
            >
              <span>{{
                item.credit > 0 ? formatNumber(item.credit, ",", "$", true) : ""
              }}</span>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import Axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import Swal from 'sweetalert2'
import { mapState } from "vuex";
export default {
  name: "listAcountEntry",
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      objAccount: [],
      isLoading: false,
			fullPage: true,
    };
  },
  computed: {
    ...mapState(["user","year"]),
  },
  mounted() {
    this.listAcountEntry();
  },

  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },
    async listAcountEntry() {
      this.isLoading = true
      const URL = `${this.URL}accountingJournal`; //se utiliza la misma ruta, que toma las ultimas transacciones del mayor
      // y luego se invierten
      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`
        },
      };
      let params = {
        company_id: this.user.companyId,
        company: this.user.companyId,
        year: this.year
        // company_id : 5,
      };
      // try {
      let rsAccount = await Axios.post(URL, params, fetchConfig);
      console.log(rsAccount);
      // return
      let res = rsAccount.data.data;
      // res.reverse()
      this.objAccount = res;
      this.isLoading = false     
    },
  },
  watch: {},
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container px-2 mx-auto dark:bg-gray-900"},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":true,"on-cancel":_vm.onCancel,"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"overflow-hidden border-l-2 border-r-2"},_vm._l((_vm.objAccount),function(item,index){return _c('div',{key:index,staticClass:"flex flex-shrink-0 justify-start text-sm hover:bg-blue-100 duration-150"},[_c('div',{staticClass:"flex items-center pl-3 w-14 h-6 px-1 border-b border-gray-300"},[_c('span',[_c('router-link',{staticClass:"border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200",attrs:{"to":{
                  name: 'NumberAccTrans',
                  params: {
                    url: 'entrynumber',
                    number: item.accounting_entry_code,
                  },
                }}},[_vm._v(" "+_vm._s(index + 1)+" ")])],1)]),_c('div',{staticClass:"flex items-center w-20 h-6 px-1 border-b border-gray-300"},[_c('router-link',{staticClass:"border-b-2 border-blue-300 hover:border-blue-500 transition ease-in-out duration-200",attrs:{"to":{
                name: 'NumberAccTrans',
                params: {
                  url: 'entrynumber',
                  number: item.accounting_entry_code,
                },
              }}},[_vm._v(" "+_vm._s(item.accounting_entry_code)+" ")])],1),_c('div',{staticClass:"flex items-center text-left w-24 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"},[_c('span',[_vm._v(_vm._s(item.transactionDate))])]),_c('div',{staticClass:"flex items-center text-left flex-1 w-32 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"},[_c('span',[_vm._v(_vm._s(item.accountCode)+" - "+_vm._s(item.accountName))])]),_c('div',{staticClass:"flex items-center text-left flex-1 w-40 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"},[_c('span',[_vm._v(_vm._s(item.description))])]),_c('div',{staticClass:"flex items-center text-left w-44 h-6 px-1 flex-wrap overflow-y-auto border-b border-gray-300"},[_c('span',[_vm._v("( "+_vm._s(item.reference_type)+": "+_vm._s(item.reference)+") P:"+_vm._s(item.auxiliary))])]),_c('div',{staticClass:"flex items-center w-24 h-6 px-1 border-b border-gray-300"},[_c('span',[_vm._v(_vm._s(item.debit > 0 ? _vm.formatNumber(item.debit, ",", "$", true) : ""))])]),_c('div',{staticClass:"flex items-center w-24 h-6 px-1 border-b border-gray-300"},[_c('span',[_vm._v(_vm._s(item.credit > 0 ? _vm.formatNumber(item.credit, ",", "$", true) : ""))])])])}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row space-x-6 rounded-t-xl"},[_c('div',{staticClass:"flex items-center justify-start"},[_c('h2',{staticClass:"my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"},[_vm._v(" Libro Diario ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"sticky container mx-auto w-full top-0"},[_c('div',{staticClass:"flex flex-row flex-shrink-0 items-center justify-start shrink-0 text-sm font-bold uppercase rounded-t-lg bg-gradient-to-t from-blue-600 to-blue-500 text-white"},[_c('div',{staticClass:"flex items-center justify-center w-14 h-10 px-1"},[_c('span',[_vm._v("N.")])]),_c('div',{staticClass:"flex items-center w-20 h-10 px-1"},[_c('span',[_vm._v("Asiento")])]),_c('div',{staticClass:"flex items-center w-24 h-10 px-1"},[_c('span',[_vm._v("Fecha")])]),_c('div',{staticClass:"flex items-center flex-1 w-32 h-10 px-1"},[_c('span',[_vm._v("Cuenta")])]),_c('div',{staticClass:"flex items-center flex-1 w-44 h-10 px-1"},[_c('span',[_vm._v("Descripción")])]),_c('div',{staticClass:"flex items-center w-40 h-10 px-1"},[_c('span',[_vm._v("Referencias")])]),_c('div',{staticClass:"flex items-center w-24 h-10 px-1"},[_c('span',[_vm._v("Debito")])]),_c('div',{staticClass:"flex items-center w-24 h-10 px-1"},[_c('span',[_vm._v("Crédito")])])])])
}]

export { render, staticRenderFns }